// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-legals-confidentialite-js": () => import("./../../../src/pages/legals/confidentialite.js" /* webpackChunkName: "component---src-pages-legals-confidentialite-js" */),
  "component---src-pages-legals-expedition-js": () => import("./../../../src/pages/legals/expedition.js" /* webpackChunkName: "component---src-pages-legals-expedition-js" */),
  "component---src-pages-legals-mentions-js": () => import("./../../../src/pages/legals/mentions.js" /* webpackChunkName: "component---src-pages-legals-mentions-js" */),
  "component---src-pages-legals-refund-js": () => import("./../../../src/pages/legals/refund.js" /* webpackChunkName: "component---src-pages-legals-refund-js" */),
  "component---src-pages-legals-utilisation-js": () => import("./../../../src/pages/legals/utilisation.js" /* webpackChunkName: "component---src-pages-legals-utilisation-js" */),
  "component---src-pages-musicgears-cabs-js": () => import("./../../../src/pages/musicgears/cabs.js" /* webpackChunkName: "component---src-pages-musicgears-cabs-js" */),
  "component---src-pages-musicgears-guitar-js": () => import("./../../../src/pages/musicgears/guitar.js" /* webpackChunkName: "component---src-pages-musicgears-guitar-js" */),
  "component---src-pages-musicgears-hardcase-js": () => import("./../../../src/pages/musicgears/hardcase.js" /* webpackChunkName: "component---src-pages-musicgears-hardcase-js" */),
  "component---src-pages-musicgears-pedalboards-js": () => import("./../../../src/pages/musicgears/pedalboards.js" /* webpackChunkName: "component---src-pages-musicgears-pedalboards-js" */),
  "component---src-pages-prints-bags-js": () => import("./../../../src/pages/prints/bags.js" /* webpackChunkName: "component---src-pages-prints-bags-js" */),
  "component---src-pages-prints-paper-print-js": () => import("./../../../src/pages/prints/paper-print.js" /* webpackChunkName: "component---src-pages-prints-paper-print-js" */),
  "component---src-pages-prints-t-shirts-js": () => import("./../../../src/pages/prints/t-shirts.js" /* webpackChunkName: "component---src-pages-prints-t-shirts-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */)
}

